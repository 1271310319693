import React, { useEffect } from 'react'
import { Configure, ConfigureProps, InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch-hooks-web'
import { usePathname } from 'next/navigation'
// components
import PlpFilterAlgolia from '@components/PlpFilter/PlpFilterAlgolia'
import { StyledPLPFilteredContent } from '@components/PlpFilter/PlpFilter.style'
import { ProductGridLayoutAlgolia } from '@components/ProductGrid'
import { PAGINATION_CONFIGS } from '@configs/catalog'
import { ICategory } from '@features/category/query'
import { algoliaClient } from '@foundation/algolia/algoliaConfig'
// utils
import getRouting from '@utils/Plp/AlgoliaRouting'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { PlpProvider } from '@utils/Plp/PlpContext'
import { IPlpState } from '@utils/Plp/PlpReducer'
// types
import { ICommerceHclPage } from '@typesApp/cms'
import { IPage } from '@typesApp/seo'
import { useNavigateForward } from '@hooks/useNavigateForward'
import { useRouter } from 'next/router'
import { formatDiscountQuery } from '@utils/url'

export interface CategoryProps {
  page: IPage
  plpState: IPlpState
  categoryData: ICategory[] | null
  parentCatalogGroupID?: string[] | null
  plpCommerce: ICommerceHclPage | undefined
}

const Category: React.FC<CategoryProps> = ({ categoryData, parentCatalogGroupID, plpState, plpCommerce }) => {
  const { categoryId, categoryIdentifier, algoliafilterCategory, groupedIndexName } = plpState
  const customerSegment = useCustomerSegmentsUtil()[0]
  const { pageLimit } = PAGINATION_CONFIGS
  const pathname = usePathname()
  const { replace } = useRouter()
  const { isForwardNavigation, targetUrl } = useNavigateForward()
  const routing = getRouting(groupedIndexName, plpState.serverUrl, customerSegment, plpState.locale)
  const handleRoute = (parameters: string[] | string) => {
    const query = Array.isArray(parameters) ? parameters.map(formatDiscountQuery).join('&') : parameters
    const replaceUrl = query ? `${pathname}?${query}` : pathname
    console.debug('[DEBUG] ~ Category ~ handleRoute ~ replaceUrl:', replaceUrl)
    replace(replaceUrl, undefined, { scroll: false })
  }

  const instantSearchConfiguration = {
    facets: ['*'],
    filters: algoliafilterCategory ?? '',
    hitsPerPage: pageLimit,
  } as ConfigureProps

  useEffect(() => {
    if (isForwardNavigation && targetUrl) {
      console.debug('[DEBUG] ~ Category ~ useEffect ~ targetUrl:', targetUrl)
      replace(targetUrl, undefined, { scroll: false })
    }
  }, [isForwardNavigation, replace, targetUrl])

  return (
    <PlpProvider initialState={plpState}>
      <InstantSearchSSRProvider {...plpState.serverState}>
        <InstantSearch searchClient={algoliaClient} indexName={groupedIndexName} routing={routing}>
          <Configure {...instantSearchConfiguration} />
          <PlpFilterAlgolia
            categoryFilter={algoliafilterCategory}
            handleRoute={handleRoute}
            searchClient={algoliaClient}
          />
          <StyledPLPFilteredContent id={categoryIdentifier}>
            <ProductGridLayoutAlgolia
              cid={`category-products-${categoryIdentifier}`}
              categoryId={categoryId}
              searchClient={algoliaClient}
              categoryFilter={algoliafilterCategory ?? ''}
              categoryData={categoryData}
              parentCatalogGroupID={parentCatalogGroupID}
              plpCommerce={plpCommerce}
              handleRoute={handleRoute}
              pathname={pathname}
            />
          </StyledPLPFilteredContent>
        </InstantSearch>
      </InstantSearchSSRProvider>
    </PlpProvider>
  )
}

export default Category
